<template>
  <div class="table-responsive" v-if="backlog">
    <b-col sm="12" md="12">
      <b-col lg="12">
        <b-form-group
          label="Filtrar"
          label-for="filter-input"
          label-cols-sm="2"
          label-align-sm="right"
          label-size="sm"
          class="mb-4"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Buscar"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Borrar</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="mb-2"
        ></b-pagination>
      </b-col>

      <b-overlay :show="overlay" rounded="md">
        <b-table
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          select-mode="single"
          responsive="sm"
          ref="selectableTable"
          :filter="filter"
          :filter-included-fields="filterOn"
          selectable
          @row-selected="onRowSelected"
        >
          <template #cell(task)="data">
            <td class="py-5 pl-0" style="width:10%">
              <div class="symbol symbol-50 symbol-light mr-2">
                <span class="symbol-label">
                  <img
                    src="media/svg/misc/tasks.svg"
                    class="h-50 align-self-center"
                    alt=""
                  />
                </span>
              </div>
            </td>
            <td class="text-left" style="width:30%">
              <span class="text-dark font-weight-bolder mb-1 font-size-lg">{{
                data.item.task.attributes.title
              }}</span>
              <span class="text-muted font-weight-bold d-block">
                {{ data.item.task.attributes.created_at }}
              </span>
            </td>
            <td class="mr-auto text-right" style="width:20%">
              <span class="text-dark font-size-sm font-weight-bolder mb-1">
                entrega
              </span>
              <b-badge :variant="getClass(data.item.task)">
                {{ data.item.task.attributes.date_end }}
              </b-badge>
            </td>
          </template>
        </b-table>
      </b-overlay>
    </b-col>
    <MODALTASK ref="modalTaskShowDashboard" />
  </div>
  <div v-else class="text-center">
    <img src="media/svg/misc/empty.svg" alt="" />
    <p class="mt-6">No hay tareas pendientes</p>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MODALTASK from "@/components/tasks-module/modal-task";
import moment from "moment";

var _ = require("lodash");
export default {
  props: {
    count: {
      type: Number,
      required: false,
      default: 4
    }
  },
  components: {
    MODALTASK
  },
  data() {
    return {
      overlay: false,
      backlog_status: 5,
      task: {},
      codesStatus: [200, 201],
      class: [
        { type: "still", class: "success" },
        { type: "toExpire", class: "warning" },
        { type: "expire", class: "danger" }
      ],
      fields: [
        {
          key: "task",
          label: "Tarea",
          sortable: true,
          class: "text-center"
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 4,
      filter: null,
      filterOn: []
    };
  },
  computed: {
    ...mapGetters(["getTasks"]),
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    tasks() {
      let tasks = [];
      if (this.count == 99) {
        tasks = _.take(this.getTasks, this.count);
      } else {
        tasks = this.getTasks;
      }
      return tasks;
    },
    backlog() {
      let target = _(this.getTasks)
        .groupBy("attributes.status_id")
        .mapValues(t => t.map(o => _.omit(o, "attributes.status_id")))
        .value();
      return target[this.backlog_status];
    },
    items() {
      let arr = [];
      if (this.backlog) {
        for (let i = 0; i < this.backlog.length; i++) {
          arr.push({
            task: this.backlog[i]
          });
        }
      }
      return arr;
    }
  },
  methods: {
    onRowSelected(items) {
      if (items.length > 0) {
        this.showColumn = true;
        this.showTask(items[0].task);
      }
    },
    getClass(item) {
      let className = "success";
      const now = moment();
      const end = moment(item.attributes.date_end_utc);
      const diffDays = end.diff(now, "days");
      const toExpire = [1, 2, 3, 4, 5, 6];
      for (let i = 0; i < this.class.length; i++) {
        if (diffDays <= 0) {
          let found = _.find(this.class, c => {
            return c.type === `expire`;
          });
          className = found.class;
        } else if (toExpire.indexOf(diffDays) !== -1) {
          let found = _.find(this.class, c => {
            return c.type === "toExpire";
          });
          className = found.class;
        } else {
          let found = _.find(this.class, c => {
            return c.type === "still";
          });
          className = found.class;
        }
      }
      return className;
    },
    showTask(task) {
      this.overlay = true;
      this.$store
        .dispatch("showTask", task.id)
        .then(response => {
          if (this.codesStatus.indexOf(response.status) != -1) {
            this.overlay = false;
            this.$refs.modalTaskShowDashboard.show();
          }
        })
        .catch(e => {
          this.overlay = false;
          console.error(e);
        });
    }
  },
  mounted() {
    this.totalRows = this.items.length;
  }
};
</script>
