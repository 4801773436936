<template>
  <b-overlay :show="overlay" rounded="md">
    <b-row>
      <b-col cols md="12" sm="12" xs="12">
        <div id="chart">
          <apexchart
            type="radialBar"
            height="350"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </b-col>
      <b-col cols md="12" sm="12" xs="12" v-show="legend" class="mt-5">
        <div class="mt-n12 position-relative zindex-0">
          <template v-for="(item, i) in list">
            <b-alert
              show
              :style="`background-color: ${item.color}`"
              v-bind:key="i"
              class="mb-2"
            >
              <b-row>
                <b-col class="ml-auto" md="2" sm="2" xs="2">
                  <div
                    class="symbol symbol-circle symbol-40 symbol-light flex-shrink-0"
                  >
                    <div class="symbol-label">
                      <span class="svg-icon svg-icon-lg svg-icon-gray-500">
                        <inline-svg :src="item.svg" />
                      </span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="auto" class="mr-auto" md="6" sm="6" xs="6">
                  <div>
                    <span
                      class="font-size-h6 text-dark-75 font-weight-bolder"
                      >{{ item.label }}</span
                    >
                  </div>
                  <div>
                    <span
                      class="font-size-h6 text-dark font-weight-bold mt-1"
                      v-if="item.count > 1"
                    >
                      {{ item.count }} tareas
                    </span>
                    <span
                      class="font-size-h6 text-dark font-weight-bold mt-1"
                      v-else
                    >
                      {{ item.count }} tarea
                    </span>
                  </div>
                </b-col>
              </b-row>
            </b-alert>
          </template>
        </div>
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import { mapGetters } from "vuex";
var _ = require("lodash");

export default {
  props: {
    legend: {
      type: Boolean,
      default: true
    },
    percentages: {
      type: Array,
      required: true,
      default: function() {
        return "done";
      }
    }
  },
  components: {},
  data() {
    return {
      overlay: false,
      average: 0,
      chartOptions: {
        chart: {
          height: 350,
          type: "radialBar"
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: "70%",
              background: "#fff",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              background: "#fff",
              strokeWidth: "67%",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35
              }
            },
            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: "#888",
                fontSize: "17px"
              },
              value: {
                formatter: function(val) {
                  return `${parseInt(val)} %`;
                },
                color: "#111",
                fontSize: "26px",
                show: true
              }
            }
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#ABE5A1"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: "round"
        },
        labels: ["Porcentage"]
      },
      colors: [
        { status: "backlog", color: "#979797", label: "Pendiente" },
        { status: "progress", color: "#2a92bf", label: "En progreso" },
        { status: "review", color: "#f4ce46", label: "En revisión" },
        { status: "done", color: "#00b961", label: "Finalizada" }
      ]
    };
  },
  computed: {
    ...mapGetters(["getTasks"]),
    groupByStatus() {
      return _(this.getTasks)
        .groupBy("attributes.status")
        .mapValues(t => t.map(o => _.omit(o, "attributes.status")))
        .value();
    },
    total() {
      return this.getTasks.length;
    },
    series() {
      let response = [];
      for (let i = 0; i < this.percentages.length; i++) {
        if (this.groupByStatus[this.percentages[i]]) {
          let average =
            (this.groupByStatus[this.percentages[i]].length * 100) / this.total;
          response.push(average);
        }
      }
      return response.length ? response : [0];
    },
    list() {
      let list = [];

      for (let [key, value] of Object.entries(this.groupByStatus)) {
        let colorFound = _.find(this.colors, c => c.status === key);
        list.push({
          title: key,
          count: value.length,
          color: colorFound ? colorFound.color : "#979797",
          label: colorFound ? colorFound.label : "Otra",
          svg: "media/svg/icons/Shopping/Chart-pie.svg"
        });
      }
      return list;
    }
  }
};
</script>
