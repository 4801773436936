<template>
  <div>
    <div class="table-responsive" v-if="formations.length">
      <b-col sm="12" md="12">
        <b-col lg="12" class="my-1">
          <b-form-group
            label="Filtrar"
            label-for="filter-input"
            label-cols-sm="2"
            label-align-sm="right"
            label-size="sm"
            class="mb-4"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Buscar"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Borrar</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="mb-2"
          ></b-pagination>
        </b-col>

        <b-overlay :show="overlay" rounded="md">
          <b-table
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            select-mode="single"
            responsive="sm"
            ref="selectableTable"
            :filter="filter"
            :filter-included-fields="filterOn"
            selectable
            @row-selected="onRowSelected"
          >
            <template #cell(formation)="data">
              <td class="py-5 pl-0 text-left" style="width:10%">
                <div class="symbol symbol-50 symbol-light mr-2">
                  <span class="symbol-label">
                    <img
                      :src="
                        data.item.formation.attributes.type == 'application/pdf'
                          ? 'media/svg/shapes/pdf.svg'
                          : data.item.formation.attributes.type == 'test'
                          ? 'media/svg/shapes/test.svg'
                          : 'media/svg/shapes/video.svg'
                      "
                      class="h-50 align-self-center"
                      alt=""
                    />
                  </span>
                </div>
              </td>
              <td class="text-left" style="width:30%">
                <span class="text-dark font-weight-bolder mb-1 font-size-lg">
                  {{ data.item.formation.attributes.name }}</span
                >
                <span class="text-muted font-weight-bold d-block">
                  {{ data.item.formation.attributes.created_at }}
                </span>
              </td>
              <td class="mr-auto text-right" style="width:20%">
                <b-badge :variant="getClass(data.item.formation)">
                  {{
                    data.item.formation.relationships.segment.attributes.name
                  }}
                </b-badge>
              </td>
            </template>
          </b-table>
        </b-overlay>
      </b-col>
    </div>
    <div v-else class="text-center">
      <img src="media/svg/misc/empty.svg" alt="" />
      <p class="mt-6">No hay formaciones pendientes</p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
var _ = require("lodash");
export default {
  props: {
    count: {
      type: Number,
      required: false,
      default: 4
    }
  },
  components: {},
  data() {
    return {
      overlay: false,
      class: [
        { type: "application/pdf", class: "success" },
        { type: "video/mp4", class: "warning" },
        { type: "test", class: "info" }
      ],
      codesStatus: [200, 201],
      fields: [
        {
          key: "formation",
          label: "Formación",
          sortable: true,
          class: "text-center"
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 4,
      filter: null,
      filterOn: []
    };
  },
  computed: {
    ...mapGetters(["getFormations", "currentUser"]),
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key };
        });
    },
    formations() {
      return _.take(this.getFormations, this.count);
    },
    items() {
      let arr = [];
      for (let i = 0; i < this.formations.length; i++) {
        if (this.currentUser.role_id != 1) {
          if (
            this.formations[i].attributes.owner == this.currentUser.id ||
            this.formations[i].attributes.active == 1
          ) {
            arr.push({
              formation: this.formations[i]
            });
          }
        } else {
          arr.push({
            formation: this.formations[i]
          });
        }
      }
      return arr;
    }
  },
  methods: {
    onRowSelected(items) {
      console.log(items);
      if (items.length > 0) {
        this.showColumn = true;
        this.showFormation(items[0].formation);
      }
    },
    getClass(item) {
      let className = "default";
      const found = _.find(this.class, c => {
        return c.type === item.attributes.type;
      });
      if (found) {
        className = found.class;
      }

      return className;
    },
    showFormation(formation) {
      this.overlay = true;
      this.$store.dispatch("purgeFormation");
      this.$store
        .dispatch("showFormation", formation.id)
        .then(response => {
          this.$router.push({
            name: `showFormation`,
            params: { path: response.attributes.name }
          });
        })
        .catch(e => {
          console.error(e.message);
          this.overlay = false;
        });
    }
  }
};
</script>
